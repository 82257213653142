<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :items="announcements"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :headers="headers"
          :no-add-button="noAddButton"
          :is-loading="isLoadingData"
          title="Pengumuman"
          subtitle="Halaman Detail Pengumuman"
          :actions-row="['detail-show']"
          @change-page="paginationHandler"
          @detail-item="showDetail"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="detailDialog"
      persistent
      header="Detail Pengumuman"
      :width="600"
      :hidden-actions="false"
      @close-modal="detailDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="announcement_data.title"
                label="Judul"
                outlined
                dense
                readonly
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-textarea
                v-model="announcement_data.content"
                label="Pesan"
                outlined
                dense
                readonly
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          large
          @click="detailDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'Announcements',
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    MainCard,
    ModalDialog,
  },
  data() {
    return {
      detailDialog: false,
      details: [],
      noAddButton: true,
      kadis_uuid: null,
      startDate: false,
      endDate: false,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingTable: true,
      isDateField: false,
      service: 'announcementhod',
      page: 1,
      totalItems: 10,
      totalPages: 0,
      dialog: null,
      search: '',
      width: 600,
      modalDialog: false,
      confirmDialog: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'JUDUL', value: 'title' },
        { text: 'PESAN', value: 'content' },
        { text: 'TAMPIL PADA', value: 'start_date' },
        { text: 'TANGGAL  UPLOAD', value: 'created' },
        { text: 'SET WAKTU', value: 'status' },
        { text: 'Detail', value: 'actions' },
      ],
      headerModal: 'Detail Pengumuman',
      announcements: [],
      announcement_data: '',
      filterQuery: {
        search: '',
      },
      formValid: false,
    }
  },
  watch: {
    page: {
      handler() {
        this.listAnnouncement(this.filterQuery)
      },
    },
  },
  beforeMount() {
    this.kadis_uuid = JSON.parse(localStorage.getItem('user')).user.details[0].kadis_uuid
  },
  mounted() {
    this.listAnnouncement()
  },
  methods: {
    async listAnnouncement(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        kadis_uuid: this.kadis_uuid,
      }).then(({ data }) => {
        this.announcements = data.data.map((announcement, index) => ({
          ...announcement,
          index: index + 1,
          created: this.getFormatDate(announcement.created_at),
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `0${createdDate.getDate()}`
      const month = `0${createdDate.getMonth() + 1}` // months are zero based
      const year = createdDate.getFullYear()

      const time = createdDate.toLocaleTimeString().replace(/(.*)\D\d+/, '$1')

      return `${year}-${month.slice(-2)}-${day.slice(-2)} ${time}`
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async showDetail(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.announcement_data = data.data
          this.detailDialog = true
        },
        err => console.error(err),
      )
    },
    closeHandler() {
      this.detailDialog = false
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
